/* stylelint-disable function-calc-no-unspaced-operator */
@value boun-xl, min-boun-4xl, boun-m, min-boun-xs, boun-2xs, boun-xs, boun-4xl, boun-2xl, boun-s from "../constants/boundaries.module.css";
@value font-2xs, font-xs, font-m, font-s from "../constants/font.module.css";
@value radioSize from '../components/atoms/Checkbox/Checkbox.module.css';

.submitBlock {
  margin-top: boun-xl;
  text-align: right;
  float: right;
  clear: both;
}

.submitStatus {
  display: block;
  margin-bottom: boun-2xs;
  color: var(--dusty-grey);
  font-weight: 500;
}

.accordionTitle {
  color: var(--royal-blue);
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  padding-top: 2rem;
}

.cardPadding {
  padding: boun-xs boun-4xl boun-2xl boun-4xl;
}

.paragNoMargin {
  margin-bottom: 0;
}

.inputMargin {
  display: inline-block;
  margin-top: boun-s;
}

.topMargin {
  margin-top: boun-s;
}

.radioList {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.radioList label {
  margin-right: 1rem;
}

.customRadioList {
  justify-content: flex-start;
  margin-left: -1rem;
}

.country {
  /* text-transform: uppercase; */
  margin-left: boun-xs;
}

.sizeInput {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.1rem;
  margin-right: boun-xs;
  transition: color 0.3s ease-in-out;
}

.sizeInput:hover {
  color: var(--primary-light);
}

.sizeInputActive {
  color: var(--primary);
}

.sizeInput > input {
  display: none;
}

.dateBlock {
  margin: boun-xs 0;
}

.date {
  color: black;
}

.fieldSet {
  background-color: var(--primary-lightest);
  border: none;
  padding: 0 boun-xs boun-m boun-xs;
  margin: 0 min-boun-xs;
}

.fieldSetWithMargin {
  margin-top: boun-s;
}

.fieldSetFullWidth {
  margin: boun-s min-boun-4xl 0 min-boun-4xl;
  padding: 0 boun-4xl boun-m boun-4xl;
  background-color: var(--athens-gray);
  border: none;
}

.interParagraph {
  color: black;
  font-size: font-s;
}

.text {
  color: black;
  font-size: font-xs;
}

.mediaLabelsRow {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.mediaLabelsRow > div {
  margin-right: boun-m;
  margin-top: boun-m;
}

.mediaLabelsRowNoMargin > div {
  margin-top: 0;
}

.mediaLabelsRowAccommodations {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mediaLabelsRowAccommodations > div {
  margin-right: boun-m;
  margin-top: boun-m;
}

.mediaLabelsRowAccommodationsNoMargin > div {
  margin-top: 0;
}

.specialityBox {
  display: block;
}

.ratesOptionName {
  color: black;
  font-size: font-s;
  font-weight: 500;
  margin: 0 0 2.4rem 0;
}

.ratesPrice {
  color: black;
  font-size: font-s;
  font-weight: 700;
  text-align: right;
}

.ratesRadio > span {
  color: black;
  font-weight: 500;
}

.upperTitle {
  color: black;
  font-size: font-s;
  text-transform: uppercase;
  font-weight: 500;
}

.ratesDescription {
  padding-left: calc(radioSize + boun-s);
  margin: 0 0 boun-2xs 0;
}

.recapLine {
  margin-bottom: boun-xs;
}

.recapLine:last-child {
  margin-bottom: boun-xl;
}

.recapLineLabel {
  color: black;
  font-size: font-xs;
  font-weight: 500;
}

.total {
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: font-m;
}

.totalBlue {
  color: var(--primary);
}

.mt2 {
  margin-top: 1.4rem;
}

.formLabel {
  display: block;
  color: #959595;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}

.spacingRight {
  padding-right: 1rem;
}

.paymentMethodOptionHeader {
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.paymentMethodOptionText {
  color: #959595;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.paymentMeanOptionHeader {
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.paymentMeanTitle {
  margin-top: 2rem;
}

.submitForm {
  margin-right: boun-xs;
}

.dropZoneWrapper {
  margin-bottom: 2rem;
}

.dropZoneLabel {
  margin-top: 2.8rem;
  margin-bottom: 0;
}

.disabled {
  opacity: 0.4;
}

.tooltip {
  display: flex;
}

.tooltipWrapper {
  display: flex;
  align-items: center;
  margin-left: 0.4rem;
}

.tooltipContent {
  width: 750px;
  text-align: center;
}

.tooltipIcon {
  height: 1rem;
  width: 1rem;
  fill: var(--primary);
}

.dropzoneRow {
  margin-top: 2rem;
}

@media (max-width: 1360px) {
  .customRadioList {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .cardPadding {
    padding: boun-xs boun-m boun-m boun-m;
  }
}

@import './constants//colors.module.css';

body {
  margin: 0;
  font-size: 16px;
  font-family: Dinot, "Helvetica Neue", sans-serif;
  color: #2b2b2b; /* default black */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

/* Fonts loading */
@font-face {
  font-family: Dinot;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local("Dinot"),
    url('/fonts/Dinot-300.woff2') format("woff2"),
    url('/fonts/Dinot-300.woff') format("woff"),
    url('/fonts/Dinot-300.ttf') format("ttf"),
    url('/fonts/Dinot-300.eot') format("eot");
}

@font-face {
  font-family: Dinot;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Dinot"),
    url('/fonts/Dinot-normal.woff2') format("woff2"),
    url('/fonts/Dinot-normal.woff') format("woff"),
    url('/fonts/Dinot-normal.ttf') format("ttf"),
    url('/fonts/Dinot-normal.eot') format("eot");
}

@font-face {
  font-family: Dinot;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local("Dinot"),
    url('/fonts/Dinot-500.woff2') format("woff2"),
    url('/fonts/Dinot-500.woff') format("woff"),
    url('/fonts/Dinot-500.ttf') format("ttf"),
    url('/fonts/Dinot-500.eot') format("eot");
}

@font-face {
  font-family: Dinot;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Dinot"),
    url('/fonts/Dinot-700.woff2') format("woff2"),
    url('/fonts/Dinot-700.woff') format("woff"),
    url('/fonts/Dinot-700.ttf') format("ttf"),
    url('/fonts/Dinot-700.eot') format("eot");
}

@value boun-xl, side-menu-width, header-height from "../../../constants/boundaries.module.css";

.menu {
  position: fixed;
  z-index: 8;
  left: 0;
  bottom: 0;
  top: 0;
  width: side-menu-width;
  background-color: white;
  box-shadow: 3px 0 15px -4px rgba(0, 0, 0, 0.16);
  padding: calc(header-height + boun-xl) 0  boun-xl 0;
  overflow-y: auto;
}

.titleBlock {
  width: 100%;
  color: black;
  box-sizing: border-box;
  padding: 0 boun-xl;
  margin-bottom: boun-xl;
}

.title {
  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.childrenList {
  list-style-type: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@value boun-xs from "../../../constants/boundaries.module.css";

.handle {
  position: relative;
  box-sizing: border-box;
}

.menu {
  /* Somme properties are from the React-Select component */
  background-color: white;
  position: fixed;
  z-index: 10;
  min-width: 10rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 0.25rem 0.6875rem hsla(0, 0%, 0%, 0.1);
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
  max-height: 18.75;
  overflow-y: auto;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

@value boun-m, boun-s, boun-2xs, min-boun-s from "../../../constants/boundaries.module.css";

/* Instyle values */
@value radioSize: 16px;
@value radioChecked: 12px;

.group {
  display: inline-block;
}

.group input[type="checkbox"] {
  display: none;
}

.label::before,
.label::after {
  content: '';
  border-radius: 0.375rem;
  transition: all 0.3s ease;
  transition-property: transform, border-color;
}

.absolute::after {
  position: absolute;
}

.label::before {
  position: absolute;
  left: 0;
  width: radioSize;
  height: radioSize;
  border: 1px solid var(--silver);
}

.label::after {
  background: url('data:image/svg+xml;utf8,<svg style="color: rgb(56, 133, 220);" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>') no-repeat;
  left: 0.1875rem;
  width: radioChecked;
  height: radioChecked;
  transform: scale(0);
  color: var(--primary);
}

.group input[type="checkbox"]:checked + .label::before {
  border-color: var(--primary);
}

.group input[type="checkbox"]:checked + .label::after {
  transform: scale(1);
}

.label {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  padding: 0 calc(radioSize + boun-s);
  margin-bottom: 0;
  cursor: pointer;
}

@value rad-s, boun-xs from "../../../constants/boundaries.module.css";
@value font-s from "../../../constants/font.module.css";

.zone {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid var(--primary-light);
  border-radius: rad-s;
  cursor: pointer;
  height: 12.5rem;
  position: relative;
}

.fileNameLink {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.fileNameLink:hover {
  color: #0056b3;
  text-decoration: underline;
}

.label {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--primary-light);
  padding: boun-xs;
  color: white;
  text-align: center;
  min-height: 4.62rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: boun-xs;
}

.dropLabel {
  color: black;
  font-weight: 700;
  font-size: font-s;
}

.dropSublabel {
  color: black;
  text-decoration: underline;
}

.picto {
  height: 3rem;
  color: var(--silver);
}

.zone,
.label {
  transition: 0.3s ease-in-out;
  transition-property: border-color, background-color;
}

.dragOver {
  border-color: var(--malachite-light) !important;
}

.dragOver .label {
  background-color: var(--malachite-light) !important;
}

.zone:hover {
  border-color: var(--primary);
}

.zone:hover .label {
  background-color: var(--primary);
}

.zone:active {
  border-color: var(--primary-lightest);
}

.zone:active .label {
  background-color: var(--primary-lightest);
}

.clear {
  height: 1.8rem;
  width: 1.8rem;
  border: none;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: var(--pomegranate);
  cursor: pointer;
}

.template {
  margin-top: 0.3rem;
  color: var(--primary);
}

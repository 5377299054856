@value boun-xs from "../../../constants/boundaries.module.css";

.btn {
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--silver);
  color: white;
  height: 1.625rem;
  width: 1.625rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: var(--primary);
}

.btn:active {
  background-color: var(--primary-lightest);
}

.btn svg {
  height: 100%;
  width: 100%;
}

.number {
  margin: 0 boun-xs;
  font-weight: 700;
  font-size: 2.5rem;
  color: var(--dusty-grey);
}

@value boun-l, boun-xs, rad-s from "../../../constants/boundaries.module.css";

.button {
  color: white;
  border-radius: rad-s;
  padding: boun-xs boun-l;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-weight: 700;
}

.fill {
  text-transform: uppercase;
  border: none;
}

.fill:disabled,
.fill-disabled {
  background-color: var(--dusty-grey) !important;
  color: black;
  cursor: default;
}

.border {
  border: 2px solid var(--primary);
}

.border:disabled,
.border-disabled {
  background-color: transparent;
  border: 2px solid var(--dusty-grey) !important;
  color: var(--dusty-grey) !important;
}

.hasLoader {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.loader {
  height: 1.5rem;
  margin-right: boun-xs;
}

.border.primary {
  color: var(--primary);
  background-color: var(--primary-lightest);
  border-color: var(--primary);
}

.fill.primary,
.fill:active.primary {
  background-color: var(--primary);
}

.fill:hover.primary,
.border:active.primary {
  background-color: var(--primary-light);
}

.border.green {
  color: var(--malachite);
  background-color: var(--malachite-lightest);
  border-color: var(--malachite);
}

.fill.green,
.fill:active.green {
  background-color: var(--malachite);
}

.fill:hover.green,
.border:active.green {
  background-color: var(--malachite-light);
}

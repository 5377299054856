.wrapper {
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out;
  box-sizing: border-box;
}

.wrapper.toggled {
  visibility: visible;
  box-sizing: border-box;
}

.content {
  box-sizing: border-box;
}

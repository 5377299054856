@value boun-2xs, boun-xl, boun-s, header-height from "../../../constants/boundaries.module.css";

.header {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 boun-xl;
  height: header-height;
  box-sizing: border-box;
}

.header.transparent {
  background-color: transparent;
}

.header.blue {
  background-color: var(--primary);
}

.header.fillWhite {
  background-color: white;
}

.logo {
  height: 2.5rem;
  flex-shrink: 0;
  cursor: pointer;
  color: white;
}

.contentRight {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.user {
  flex-shrink: 0;
  color: white;
  margin-left: boun-s;
}

.header.fillWhite .logo,
.header.fillWhite .user {
  color: black;
}

.menuItem {
  box-sizing: border-box;
  width: 100%;
  padding: boun-2xs boun-s;
  transition: 0.3s ease-in-out;
  transition-property: color, background-color;
}

.menuItem:hover {
  color: var(--primary);
  background-color: var(--primary-lightest);
}

.menuItem:active {
  background-color: var(--primary-light);
}

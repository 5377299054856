@value boun-2xs, boun-s, boun-xs from "../../../constants/boundaries.module.css";

.circle {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border: 3px solid var(--primary);
  border-radius: 50%;
  color: var(--primary);
  background-color: white;
  transition: box-shadow 0.3s ease-in-out;
}

.active {
  composes: shadow-rest-closer from "../../../constants/helpers.css";
}

.complete {
  border-color: var(--malachite);
  color: var(--malachite);
}

.number {
  font-size: 1rem;
  font-weight: 700;
}

.icon {
  color: var(--malachite);
  width: 1.6rem;
  height: 1.6rem;
}

@value boun-2xs, boun-s, boun-xl from "../../../constants/boundaries.module.css";

.wrapper {
  text-decoration: none;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-height: 4rem;
  width: 100%;
  padding: boun-2xs boun-xl;
  background-color: white;
  color: var(--primary);
  opacity: 1;
  list-style-type: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}

.wrapper:hover,
.wrapper.active {
  background-color: var(--primary);
  color: white;
  opacity: 1;
}

.wrapper:active {
  background-color: var(--primary-lightest);
}

.wrapper.active {
  padding-right: calc(boun-xl - 10px);
  border-right: 10px solid var(--accent);
}

.wrapper.disabled {
  cursor: initial;
  opacity: 0.5;
}

.wrapper.disabled:hover {
  background-color: var(--primary-lightest);
}

.label {
  flex-grow: 1;
  margin: 0 boun-s;
  font-weight: 500;
}

.label.noIcon {
  margin: 0;
}

.icon {
  flex-shrink: 0;
  height: 2rem;
}

.children {
  flex-shrink: 0;
}

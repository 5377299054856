.flash {
  padding: 10px 15px;
  background-color: var(--primary-lightest);
  color: var(--primary-dark);
  border-radius: 5px;
  border: 1px solid var(--primary-dark);
  text-align: left;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
}

.flash.success {
  background-color: var(--malachite-lightest);
  color: var(--malachite-dark);
  border-color: var(--malachite-dark);
}

@value boun-xs, boun-2xs from "../../../constants/boundaries.module.css";

.inputGroup {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.selectCountry {
  max-width: 7rem;
  flex-shrink: 0;
}

.country {
  text-transform: uppercase;
  margin-left: boun-2xs;
}

.phoneCode {
  margin-left: boun-2xs;
  padding-right: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 1.25rem;
  color: black;
  border-bottom: 1px solid transparent;
}

@value boun-xs, boun-m, boun-2xl from "../../../constants/boundaries.module.css";

.listItem {
  list-style-type: none;
  width: 100%;
  padding: boun-xs boun-2xl;
  border-top: 1px solid var(--silver);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
}

.linkWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.listItem:last-child {
  border-bottom: 1px solid var(--silver);
}

.listItem:hover {
  background-color: var(--athens-gray);
}

.listItem:active {
  background-color: white;
}

.listItem::after {
  content: '';
  position: absolute;
  right: boun-2xl;
  top: 50%;
  background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>') no-repeat;
  width: 1rem;
  height: 1rem;
  transform: translateY(-50%) rotate(90deg);
}

.avatar,
.picto {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.text {
  flex-grow: 1;
  margin-left: boun-m;
  color: var(--primary);
  font-weight: 700;
}

@value boun-xs, boun-2xs, boun-m, rad-s from "../../../constants/boundaries.module.css";
@value font-l from "../../../constants/font.module.css";

.thumbnailInput {
  margin: boun-xs 0;
}

.thumbnailInput input {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
}

.thumbnailBtn {
  display: block;
  text-align: center;
  color: white;
  border-radius: rad-s;
  padding: boun-2xs boun-m;
  cursor: pointer;
  font-weight: 700;
  background-color: var(--accent);
  text-transform: uppercase;
  border: none;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.thumbnailBtn:hover {
  opacity: 0.7;
}

.preview {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin: boun-m auto 0 auto;
}

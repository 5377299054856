@value boun-m, boun-xs from "../../../constants/boundaries.module.css";
@value font-2xs from "../../../constants/font.module.css";

.block {
  width: 100%;
  margin-top: boun-m;
}

.noMargin {
  margin-top: 0;
}

.label {
  display: block;
  color: var(--dusty-grey);
  font-size: font-2xs;
  margin-bottom: boun-xs;
}

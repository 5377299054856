@value boun-l, boun-2xl, boun-m from "../constants/boundaries.module.css";
@value font-l, font-2xs from "../constants/font.module.css";

.textBlock {
  width: 100%;
  text-align: center;
  margin-bottom: boun-l;
}

.connect,
.accent {
  display: block;
  font-size: font-l;
  font-weight: 700;
}

.accent {
  color: var(--primary);
}

.form {
  width: 70%;
  margin: 0 auto boun-2xl auto;
  text-align: center;
}

.submit {
  margin-top: boun-m;
  margin-left: auto;
  margin-right: auto;
}

.legals {
  font-size: font-2xs;
  color: var(--dusty-grey);
}

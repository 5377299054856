:root {
  --malachite-dark: #199f37;
  --malachite: #16d440;
  --malachite-light: #a1edb2;
  --malachite-lightest: #e7faeb;
  --royal-blue-dark: #2e6db3;
  --royal-blue: #3885dc;
  --royal-blue-light: #74aae7;
  --royal-blue-lightest: #d7e7f8;
  --web-orange: #ffa800;
  --pomegranate: #f13929;
  --black: #2b2b2b;
  --dusty-grey: #959595;
  --silver: #bfbfbf;
  --athens-gray: #eff0f2;
  --white: #fff;

  /* Color aliases */
  --primary: var(--royal-blue);
  --primary-light: var(--royal-blue-light);
  --primary-lightest: var(--royal-blue-lightest);
  --primary-dark: var(--royal-blue-dark);
  --accent: var(--web-orange);
}

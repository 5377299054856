@value boun-m, boun-s, boun-2xs, min-boun-s from "../../../constants/boundaries.module.css";

/* Instyle values */
@value radioSize: 16px;
@value radioChecked: 12px;

.group {
  display: inline-block;
}

.group input[type="radio"] {
  display: none;
}

.label::before,
.label::after {
  content: '';
  border-radius: 50%;
  transition: all 0.3s ease;
  transition-property: transform, border-color;
}

.absolute::after {
  position: absolute;
}

.label::before {
  position: absolute;
  left: 0;
  width: radioSize;
  height: radioSize;
  border: 1px solid var(--silver);
}

.label::after {
  left: 0.1875rem;
  width: radioChecked;
  height: radioChecked;
  transform: scale(0);
  background: var(--primary);
}

.group input[type="radio"]:checked + .label::before {
  border-color: var(--primary);
}

.group input[type="radio"]:checked + .label::after {
  transform: scale(1);
}

.label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  /* height: radioSize; */
  position: relative;
  padding: 0 calc(radioSize + boun-s);
  margin-bottom: 0;
  cursor: pointer;
}

@value boun-l, boun-s from "../constants/boundaries.module.css";
@value font-l from "../constants/font.module.css";

.card {
  max-width: 45%;
  margin: 0 auto;
}

.cardTitle {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: font-l;
  margin: boun-l 0;
  box-sizing: border-box;
}

.playerList {
  margin-bottom: boun-s;
}

.loaderWrapper {
  width: 100%;
  color: var(--primary);
  box-sizing: border-box;
  text-align: center;
  margin-bottom: boun-s;
}

@media (max-width: 1200px) {
  .card {
    max-width: 100%;
  }
}

@media (min-width: 1201px) and (max-width: 1700px) {
  .card {
    max-width: 75%;
  }
}

.select {
  appearance: none;
  background: transparent;
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
  width: 3rem;
  cursor: pointer;
}

.select option {
  color: black;
}

.dark .select {
  color: black;
}

.light .select {
  color: white;
}

.chevron {
  width: 100%;
  position: relative;
}

.chevron::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
}

.chevron.dark::after {
  /* with color black */
  background: url('data:image/svg+xml;utf8,<svg style="color: rgb(43, 43, 43);" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>');
  background-repeat: no-repeat;
}

.chevron.light::after {
  /* with color white */
  background: url('data:image/svg+xml;utf8,<svg style="color: rgb(255, 255, 255);" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>');
  background-repeat: no-repeat;
}

@value boun-xs, boun-3xl, boun-l, boun-m, boun-xl, side-menu-width, footer-height, header-height from "../../constants/boundaries.module.css";
@value font-l from "../../constants/font.module.css";

body {
  background-color: var(--athens-gray);
}

.wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: var(--athens-gray);
  padding: header-height 0 calc(footer-height + boun-3xl) side-menu-width;
  box-sizing: border-box;
  color: var(--dusty-grey);
}

.pageTitle {
  font-size: font-l;
  margin: 0 0 boun-m 0;
  padding-left: boun-l;
  color: black;
}

.titleRow {
  padding-top: boun-xl;
}

.sides {
  margin-top: boun-xl;
}

.hintsWrapper {
  position: relative;
}

.sideStick {
  position: sticky;
  background-color: var(--primary-dark);

  /* top: 8rem; */
}

.main {
  padding: 0 calc(boun-3xl - 1rem) 0 boun-m;
}

.footer {
  padding: 0 3rem 0 calc(side-menu-width + 4rem);
}

.menuMobile {
  display: none;
}

@media (max-width: 1200px) {
  .wrapper {
    padding-left: 0;
  }

  .main {
    padding: 0 boun-xs;
  }

  .pageTitle {
    padding-left: boun-xs;
  }

  .sides,
  .sideStick {
    display: none;
  }

  .menuMobile {
    display: block;
  }

  .sideMenu {
    display: none;
  }

  .footer {
    padding: 0 3rem;
  }
}

@media (max-width: 750px) {
  .footer {
    padding: 1rem 1rem;
  }
}

.imageBackground {
  width: inherit;
}

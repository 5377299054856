@value boun-s, boun-4xl from "../constants/boundaries.module.css";
@value font-m, font-xs from "../constants/font.module.css";

.dropZoneWrapper {
  margin-bottom: 2rem;
}

.filesTitle {
  color: black;
  font-size: font-m;
  font-weight: 400;
}

.subtitle {
  color: var(--primary);
  font-size: font-xs;
  font-weight: 500;
  text-transform: uppercase;
}

.dropzonesWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.btnWrapper {
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  margin-top: boun-s;
}

.cardStatusBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--primary);
  color: white;
  padding: boun-s boun-4xl;
}

.statusTitle {
  font-size: font-xs;
  font-weight: 400;
}

.status {
  text-transform: uppercase;
  font-weight: 500;
}

.incomplete {
  background-color: var(--pomegranate);
}

.complete {
  background-color: var(--malachite);
}

.statusBtn {
  background-color: white;
}

.incomplete .statusBtn {
  background-color: white;
  color: var(--pomegranate);
}

.complete .statusBtn {
  background-color: white;
  color: var(--malachite);
}

.complete .statusBtn:hover {
  background-color: var(--malachite-lightest);
}

.cardStatusContent {
  padding: boun-s;
}

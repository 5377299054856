@value boun-2xl, boun-m, boun-xs, footer-height from "../../../constants/boundaries.module.css";

@media only screen and (max-width: 750px) {
  .footer {
    display: none;
  }

  .footerMobile {
    left: 0;
    right: 0;
    bottom: 0;
    height: footer-height;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    padding: 2rem boun-xs;
  }

  .footerMobileElementSpacing {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .footerMobile.white,
  .footerMobile.white .terms {
    color: white;
  }

  .footerMobile.black,
  .footerMobile.black .terms {
    color: black;
  }

  .picto {
    display: none;
  }
}

@media (min-width: 751px) {
  .footerMobile {
    display: none;
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: footer-height;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0 boun-2xl;
  }
}

.footer span:last-child {
  flex-grow: 1;
  text-align: right;
}

.picto {
  height: 1rem;
  margin-right: boun-xs;
}

.textBlock {
  margin-right: boun-m;
}

.terms {
  margin-right: boun-xs;
  text-decoration: none;
}

.terms:last-child {
  margin-right: 0;
}

.footer.white,
.footer.white .terms {
  color: white;
}

.footer.black,
.footer.black .terms {
  color: black;
}

.contactAndTerms {
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

@media (max-width: 1852px) {
  .footer {
    height: 6rem;
  }

  .footer span:last-child {
    flex-grow: 0;
    text-align: left;
  }

  .contactAndTerms {
    order: 0;
    flex-grow: 1;
    width: 100%;
  }

  .contactWrapper,
  .termsWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
  }

  .terms {
    margin: 0;
    text-align: right;
  }

  .textAddress {
    order: 1;
  }
}

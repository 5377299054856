@value boun-3xl, boun-l, boun-m, boun-xl, boun-xs, footer-height, header-height from "../../constants/boundaries.module.css";
@value font-l from "../../constants/font.module.css";

.wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: var(--athens-gray);
  padding: header-height 0 calc(footer-height + boun-3xl) 0;
  box-sizing: border-box;
  color: var(--dusty-grey);
}

.main {
  padding: boun-xl boun-xs 0 boun-xs;
}

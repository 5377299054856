@value boun-l, boun-2xl, boun-s from "../constants/boundaries.module.css";
@value font-l, font-xl from "../constants/font.module.css";

.card {
  height: 32rem;
}

.cardContent {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  font-weight: 500;
}

.cardButton {
  font-size: 0.85rem;
  padding: 0.7rem 1.5rem;
}

.catchLine {
  font-size: font-xl;
  color: var(--primary);
  font-weight: 700;
}

.subline {
  font-size: font-l;
  font-weight: 700;
}

.baseText {
  margin: boun-s 0 boun-l 0;
}

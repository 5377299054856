.acceptParacetamolRadios {
  margin-top: 1.1rem;
}

.form_section {
  margin-bottom: 3rem;
}

.form_section_large {
  margin-bottom: 4rem;
}

.form_section_small {
  margin-bottom: 2rem;
}

.margin_top {
  margin-top: 4rem;
}

.margin_top_small {
  margin-top: 2rem;
}

.no_mb {
  margin-bottom: 0;
}

.padding_top {
  padding-top: 2rem;
}

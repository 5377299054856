@value boun-3xl, boun-xl, footer-height, header-height from "../../constants/boundaries.module.css";
@value font-xl, font-m, font-l from "../../constants/font.module.css";

.wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: header-height 0 calc(footer-height + boun-3xl) 0;
  box-sizing: border-box;
  background-color: var(--primary-lightest);
}

.wrapperCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main {
  width: 60%;
  margin: 0 auto;
}

.titlesBlock {
  text-align: center;
  margin: boun-3xl 0 boun-xl 0;
}

.title,
.subtitle {
  display: block;
  font-size: font-xl;
}

.title {
  color: white;
}

.subtitle {
  font-weight: 700;
  color: var(--royal-blue-light);
}

@media (max-width: 768px) {
  .title,
  .subtitle {
    font-size: font-l;
  }

  .main {
    width: 100%;
  }
}

@media (max-width: 460px) {
  .wrapper {
    padding: header-height 0 calc(9rem + boun-3xl) 0;
  }

  .title,
  .subtitle {
    font-size: font-m;
  }
}

@value boun-xs from "../../../constants/boundaries.module.css";

.content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
}

.pictureWrapper {
  position: relative;
  line-height: 0;
}

.userPicto,
.picture {
  height: 8rem;
  width: 8em;
}

.picture {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.userPicto {
  color: var(--dusty-grey);
}

.editBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--primary-light);
  color: white;
  border: none;
  border-radius: 50%;
  height: 1.8rem;
  width: 1.8rem;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transition-property: background-color;
}

.editBtn:hover {
  background-color: var(--primary);
}

.editBtn:active {
  background-color: var(--primary-light);
}

.label {
  text-transform: uppercase;
  margin-top: boun-xs;
}

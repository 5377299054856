@value boun-m, boun-xs, boun-4xl from "../../../constants/boundaries.module.css";

.wrapper,
.nav {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

.nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.navItem {
  list-style-type: none;
  width: 100%;
  padding: boun-xs 0;
  color: var(--primary-lightest);
  background-color: var(--athens-gray);
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  padding-left: boun-4xl;
}

.navItem:hover {
  color: var(--primary);
}

.active {
  color: var(--primary);
  background-color: white;
}

.content {
  padding: boun-xs boun-4xl boun-xs boun-4xl;
}

@media (max-width: 768px) {
  .navItem {
    padding-left: boun-m;
  }

  .content {
    padding: boun-xs boun-m boun-m boun-m;
  }
}

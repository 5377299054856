/* Life will be easier */

.shadow-rest {
  box-shadow: 1px 6px 28px -8px rgba(43, 43, 43, 0.35);
}

.shadow-rest-closer {
  box-shadow: 1px 4px 11px -2px rgba(43, 43, 43, 0.55);
}

.card-title {
  color: #3885dc;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.card-title-margin {
  margin-top: 2rem;
}

.card-margin {
  margin-bottom: 1rem;
}

@media (max-width: 700px) {
  .container-fluid {
    padding: 0 1rem !important;
  }
}

@value header-height, boun-xs, boun-2xs, boun-xl, header-height from "../../../constants/boundaries.module.css";

.titleBlock {
  width: 100%;
  color: black;
  box-sizing: border-box;
  padding: 0 boun-xl;
  margin: boun-xs 0;
}

.title {
  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.navBar {
  position: sticky;
  top: header-height;
  z-index: 8;
  background-color: white;
  color: black;
  composes: shadow-rest from "../../../constants/helpers.css";
}

.navList {
  list-style-type: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navItem {
  height: 2.5rem;
  width: 100%;
  text-align: center;
  padding: boun-2xs 0;
  transition: 0.3s ease-in-out;
  transition-property: background-color;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItem:active {
  background-color: var(--primary);
}

.navItemWrapper {
  display: flex;
  flex-flow: column wrap;
}

.active {
  background-color: var(--primary);
}

.active .navItemWrapper {
  color: white;
}

.picto {
  color: black;
  height: 1.65rem;
}

.active .picto {
  color: white;
}

.percent {
  position: absolute;
  bottom: 0;
  height: 0.13rem;
  width: 100%;
  background-color: var(--athens-gray);
}

.progress {
  position: absolute;
  background-color: var(--malachite);
  height: 100%;
}

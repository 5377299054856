@value boun-2xs, rad-s from "../../../constants/boundaries.module.css";

.wrapper {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 0.75rem;
  position: relative;
  min-width: 6.1rem;
}

.wrapperLeft {
  display: inline-flex;
  flex-flow: column nowrap;
  font-size: 0.75rem;
  position: relative;
  min-width: 6.1rem;
}

.media {
  padding: 0.5rem;
  border: none;
  outline: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  background-color: var(--silver);
  color: white;
  border-radius: rad-s;
  margin-bottom: boun-2xs;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.image {
  width: 100%;
  height: auto;
}

.media svg {
  width: 2rem;
  height: 2rem;
}

.btnBorder {
  background-color: transparent;
  border: 1px solid black;
  color: black;
  transition:
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}

.active.btnBorder,
.btnBorder:hover {
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.btnBorder:active {
  border: 1px solid var(--primary-lightest);
  color: var(--primary-lightest);
}

.active {
  background-color: var(--primary);
}

.closeBtn {
  position: absolute;
  top: -0.25rem;
  right: 0.4rem;
  height: 1.5rem;
  width: 1.5rem;
  color: white;
  background-color: var(--pomegranate);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.closeBtn svg {
  height: 1rem;
  width: 1rem;
}

@value boun-xs, boun-4xl, boun-2xl, boun-s, boun-m, rad-s from "../../../constants/boundaries.module.css";

.card {
  width: 100%;
  background-color: white;
  overflow: hidden;
  composes: shadow-rest from "../../../constants/helpers.css";
}

.title {
  font-size: 1rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  padding: boun-xs boun-4xl;
  margin: 0;
  background-color: var(--primary);
}

.content {
  padding: boun-xs boun-4xl boun-2xl boun-4xl;
}

.blue {
  background-color: var(--primary-lightest);
}

.withImage {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
}

.image {
  flex-shrink: 0;
  width: 50%;
  background-size: cover;
  background-position: center;
}

.withImage .content {
  padding: boun-m boun-m;
}

.rounded {
  border-radius: rad-s;
}

.noPadding .content {
  padding: 0;
}

@media (max-width: 768px) {
  .content {
    padding: boun-xs boun-m boun-m boun-m;
  }
}

@media (max-width: 1100px) {
  .image {
    display: none;
  }
}

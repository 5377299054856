@value boun-xs, boun-xl from "../../../constants/boundaries.module.css";

.wrapper {
  list-style-type: none;
  background-color: var(--athens-gray);
  padding: boun-xs boun-xl;
  margin-bottom: boun-xs;
  color: black;
}

.status {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}

.error {
  background-color: var(--pomegranate);
}

.message {
  margin: 0;
}

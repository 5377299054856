.wrapper {
  display: inline-block;
  line-height: 0;
}

.avatar {
  box-sizing: border-box;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  object-position: center;
  position: relative;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}

.avatar:active {
  border: 2px solid var(--primary-light);
}

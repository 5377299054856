@value boun-m, boun-s, boun-xs, boun-2xs, min-boun-s from "../../../constants/boundaries.module.css";

.group {
  flex: 1 1 0;
  text-align: center;
  margin-bottom: boun-xs;
}

.label {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  cursor: pointer;
  margin: 0 !important;
}

.label.disabled {
  cursor: default;
}

.input {
  display: none;
}

.subLabel {
  margin: 0 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid var(--silver);
  background-color: var(--silver);
  color: white;
  text-align: center;
  line-height: 2rem;
  transition: 0.3s ease-in-out;
  transition-property: color, background-color, border;
}

.subLabel.active {
  background-color: var(--primary-lightest);
  color: var(--primary);
  border: 1px solid var(--primary);
}

@media (max-width: 1890px) {
  .group {
    flex: 1 0 25%;
    text-align: center;
    margin-bottom: boun-2xs;
  }
}

@media (max-width: 1360px) {
  .group {
    flex: 0 0 33%;
  }
}

@media (max-width: 480px) {
  .group {
    flex: 0 0 50%;
  }
}

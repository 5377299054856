@value boun-2xs, boun-s, boun-xs from "../../../constants/boundaries.module.css";

.label {
  width: 100%;
  color: var(--dusty-grey);
}

.inputGroup {
  position: relative;
  width: 100%;
  margin-top: boun-xs;
}

.input {
  padding: 0.5rem 0;
  width: 100%;
  position: relative;
  z-index: 2;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
}

.input::-webkit-slider-thumb {
  font-size: 16px;
  -webkit-appearance: none;
  height: 1.25rem;
  width: 1.25rem;
  border: 0.1875rem solid var(--primary);
  border-radius: 50%;
  background-color: white;
}

.input::-moz-range-thumb {
  font-size: 16px;
  height: 1.25rem;
  width: 1.25rem;
  border: 0.1875rem solid var(--primary);
  border-radius: 50%;
  background-color: white;
}

.withLabels {
  margin-top: calc(2 * boun-s + boun-2xs); /* equivalent of a label height + a margin */
}

.rangeLabelLeft,
.rangeLabelRight {
  position: absolute;
  top: calc((100% + boun-2xs) * -0.8);
  padding: 0.3rem boun-s;
  border: 1px solid var(--silver);
  border-radius: 2rem;
  color: var(--silver);
  background-color: transparent;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

.rangeLabelLeft {
  left: 0;
}

.rangeLabelRight {
  right: 0;
}

.rangeLabelActive {
  border-color: var(--primary);
  color: white;
  background-color: var(--primary);
}

.dots {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-0.45rem);
  padding: 0 0.625rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--silver);
  transition: background-color 0.3s ease-out;
}

.active {
  background-color: var(--primary);
  position: relative;
}

.rulerbar,
.progress {
  box-sizing: border-box;
  height: 0.1875rem;
  position: absolute;
  z-index: 0;
  left: 0.625rem;
  right: 0.625rem;
  top: 50%;
  background-color: var(--silver);
  transform: translateY(-0.3rem);
  transition: width 0.3s ease-out;
}

.progress {
  background-color: var(--primary);
  z-index: 1;
}

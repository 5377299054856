@value boun-xl, boun-xs from "../../../constants/boundaries.module.css";

.wrapper {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--primary);
  color: white;
  padding: boun-xl;
  overflow: hidden;
}

.title {
  margin: 0 0 boun-xs 0;
  color: var(--accent);
  font-size: 2rem;
  font-weight: 700;
}

.background {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.backgroundDeffered {
  position: absolute;
  z-index: 0;
  top: -20%;
  right: -20%;
  width: 100%;
}
